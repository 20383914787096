.contact-container {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
  }
  
  .content-container {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
