body {
  margin: 0;
  padding: 0;
  background-color: #0f1117;
}

.fade-in {
  opacity: 0;
  animation: fadeIn 4s ease-out forwards;
}

a {
  text-decoration: underline;
  color: white;
  transition: transform 0.5s;
  display: inline-block;
}

a:hover {
  transform: scale(1.1);
}



@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes rainbow {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

.rainbow {
  background: linear-gradient(
    to right,
    #ff00ff,
    #1aff1a,
    #00ffff,
    #ff00ff,
    #1aff1a,
    #00ffff,
    #ff00ff
  );
  background-size: 600% auto;
  color: transparent;
  background-clip: text;
  -webkit-background-clip: text;
  animation: rainbow 2s linear infinite;
}
